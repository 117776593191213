
import {html, LitElement} from 'lit';
import {customElement, state} from 'lit/decorators.js';

enum HealthCheckStatus  { PENDING = 'Pending', SKIPPED = 'Skipped', FAILED = 'Failed', PASSED = 'Passed' }
export enum HealthCheckName {
	SCRIPT_PRESENT = 'Script Present',
	SCRIPT_LOADED = 'Script Loaded',
	INTEGRATION_ID_MATCHES = 'Integration Id Matches',
	PAGE_EVALUATED = 'Page Evaluated',
	MESSAGING_ACTIVE = 'Messaging Active',
}

type HealthCheck = {
	name: HealthCheckName;
	status: HealthCheckStatus;
};
type OnboardStatusSuccess = {
	type: 'success';
	checks: HealthCheck[];
}

type OnboardStatusError = {
	type: 'error';
	error: string;
};

type OnboardStatusPayload = OnboardStatusSuccess | OnboardStatusError;

type OnboardStatusResponse = {
	type: 'triptease.onboard.response';
	correlationId: string;
	payload: OnboardStatusPayload;
}

function isOnboardSuccess(payload: OnboardStatusPayload): payload is OnboardStatusSuccess {
	return payload.type === 'success';
}

function isOnboardEvent(event: MessageEvent): event is MessageEvent<OnboardStatusResponse> {
	return event.data?.type === 'triptease.onboard.response';
}

@customElement('gtm-script-checker')
export class ScriptChecker extends LitElement {
	@state()
	private pageUrl?: URL;

	@state()
	private checking: boolean = false;

	@state()
	private detected: boolean = false;

	@state()
	private checked: boolean = false;

	@state()
	private checkedCount: number = 0;

	private window: Window | null = null;

	private interval: number = 0;

	private correlationId?: string;

	private integrationId?: string;

	private _onHeartbeat = (event: MessageEvent) => {
		if (!isOnboardEvent(event)) return;
		if (event.data.correlationId !== this.correlationId) return;
		const payload = event.data.payload;

		if (!isOnboardSuccess(payload)) {
			this.checked = true;
			this.detected = false;
			this.checking = false;
			clearInterval(this.interval);
			window.removeEventListener('message', this._onHeartbeat);
			return;
		}

		const checksToVerify = [HealthCheckName.SCRIPT_PRESENT, HealthCheckName.SCRIPT_LOADED];
		if (this.integrationId) {
			checksToVerify.push(HealthCheckName.INTEGRATION_ID_MATCHES);
		}

		const hasFailures = checksToVerify.every(check => payload.checks.find(c => c.name === check)?.status === HealthCheckStatus.FAILED);

		if (hasFailures) {
			this.checked = true;
			this.detected = false;
			this.checking = false;
			clearInterval(this.interval);
			window.removeEventListener('message', this._onHeartbeat);
			return;
		}

		const allChecksPassed = checksToVerify.every(check => payload.checks.find(c => c.name === check)?.status === HealthCheckStatus.PASSED);


		if (allChecksPassed) {
			clearInterval(this.interval);
			window.removeEventListener('message', this._onHeartbeat);
			this.detected = true;
			this.checking = false;
			this.checked = true;
		}
	};


	private _checkScript = (event: Event) => {
		event.preventDefault();

		this.window = window.open(this.pageUrl, '_blank', 'width=800&height=800');

		this.checking = true;
		this.detected = false;
		this.checked = false;


		this.checkedCount = 0;
		this.interval = setInterval(() => {
			if (this.checkedCount === 30) {
				// crude 30s timeout, gives user 30s to perform actions that may need to be done e.g. confirm cookie banner
				clearInterval(this.interval);
				this.checking = false;
				this.checked = true;

				return;
			}
			if (this.window) {
				this.correlationId = crypto.randomUUID();
				const integrationInput = <HTMLInputElement>document.getElementById('integration-id');
				this.integrationId = integrationInput.value;

				this.window.postMessage({
					type: 'triptease.onboard.request',
					requestType: 'evaluateHealth',
					payload: [{ integrationId: this.integrationId }],
					correlationId: this.correlationId
				}, this.pageUrl!.origin);
			} else {
				console.log('No window :(');
			}
			this.checkedCount += 1;
		}, 1000);

		window.addEventListener('message', this._onHeartbeat);

		return false;
	};

	private _updateUrl = (event: Event) => {
		event.preventDefault();
		this.pageUrl = new URL((event.target as HTMLInputElement).value);
	};


	private renderStatus() {
		if (this.checking) {
			const remaining = 30 - this.checkedCount;
			return html`<p>⏳ Checking (${remaining}s)...</p>`;
		}

		if (this.detected) {
			return html`<p>✅ Script detected!</p>`;
		}

		if (this.checked && !this.detected) {
			return html`<p>❌ Script not detected </p>`;
		}

		return null;
	}

	override render() {
		return html`
            <section>
                <h2>Check your integration</h2>
                <form @submit=${this._checkScript}>
                    <label>
                        Page URL:
                        <input type="text" name="page-url" id="page-url" @change=${this._updateUrl}/>
                    </label>

                    <button id="check-script" type="submit">Check script</button>
                </form>

                ${this.renderStatus()}
            </section>`;
	}
}
