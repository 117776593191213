
import {css, html, LitElement, PropertyValues} from 'lit';
import {customElement, property, state} from 'lit/decorators.js';

export * from './script-checker.js';

declare global {
	export function listAccounts(): Promise<Account[]>;

	export function onAddTagPressed(path: string): Promise<void>;

	export function listContainers(path: string): Promise<GTMElement[]>;

	export function listWorkspaces(path: string): Promise<GTMElement[]>;

	export function listTags(path: string): Promise<GTMElement[]>;
}

interface Account {
	path: string;
	name: string;
	accountId: string;
}

interface Container extends Account {
	containerId: string;
}

interface Workspace extends Container {
	workspaceId: string;
}

interface Tag extends Workspace {
	tagId: string;
}

type  GTMElement = Tag | Workspace | Container | Account;

const isTag = (element: GTMElement): element is Tag => element.hasOwnProperty('tagId');
const isWorkspace = (element: GTMElement): element is Workspace => element.hasOwnProperty('workspaceId') && !isTag(element);
const isContainer = (element: GTMElement): element is Container => element.hasOwnProperty('containerId') && !isWorkspace(element);
const isAccount = (element: GTMElement): element is Account => !isContainer(element);

@customElement('gtm-account-container')
export class AccountContainer extends LitElement {
	@property({type: Object})
	public account?: Account;

	override render() {
		if (!this.account) {
			return html`
                <li>No account</li>`;
		}
		return html`
            <li>
                <gtm-element .element=${this.account}></gtm-element>
            </li>`;
	}
}

@customElement('gtm-element')
export class GtmElement extends LitElement {
	@property({type: Object})
	public element?: GTMElement;

	@state()
	private open: boolean = false;

	@state()
	private records: GTMElement[] = [];

	@state()
	private loading: boolean = false;

	static override styles = css`
		li {
			list-style: none;
		}
		
		details {
			width: 100%;
			min-height: 30px;
			min-width: 400px;
			
			
			summary {
				font-size: var(--font-size-base);
				font-weight: var(--font-weight-normal);
				cursor: pointer;
			}
		}
	`;

	private _onToggle = (event: Event) => {
		this.open = !this.open;

		console.log('event', event);
		console.log('element', this.element);

		if (this.open) {
			this.loadChildren();
		}

	};

	private loadChildren = async () => {
		if (!this.element) {
			return;
		}

		if (isTag(this.element)) {
			this.loading = false;
			return;
		}

		this.loading = true;

		if (isWorkspace(this.element)) {
			this.records = await listTags(this.element.path);
		} else if (isContainer(this.element)) {
			this.records = await listWorkspaces(this.element.path);
		} else if (isAccount(this.element)) {
			this.records = await listContainers(this.element.path);
		}
		this.loading = false;
	};

	private _installScript = () => {
		if (!this.element || !isWorkspace(this.element)) {
			return;
		}

		console.log('install script', this.element);
		this.dispatchEvent(new CustomEvent('install-script', {
			bubbles: true,
			composed: true,
			detail: {
				workspace: this.element,
			}
		}));
	};

	override render() {
		if (!this.element) {
			return html`
                <li>No element</li>`;
		}

		if (isTag(this.element)) {
			return html`
                <li>${this.element.name}</li>`;
		}

		return html`
            <li>
                <details .open=${this.open} @toggle=${this._onToggle}>
                    <summary>${this.element.name}</summary>
                    ${this.loading
                            ? html`<p>Loading...</p>`
                            : html`
                                <ul>${this.records.map(child => html`
                                    <gtm-element .element=${child}></gtm-element>`)}
                                </ul>`}

                    ${isWorkspace(this.element) ? html`
                        <button @click=${this._installScript}>Install script in this workspace</button>` : ''}
                </details>
            </li>`;
	}
}

@customElement('gtm-tag-list')
export class TagList extends LitElement {
	@property({type: Boolean,})
	public ready: boolean = false;

	@state()
	private accounts: Account[] = [];

	@state()
	private loading: boolean = true;

	@state()
	private installing: boolean = false;

	@state()
	private error: boolean = false;

	@state()
	private installed: boolean = false;

	@state()
	private installedOn: string = '';

	private loadTags = async () => {
		this.loading = true;
		this.accounts = await listAccounts();
		this.loading = false;
	};

	static override styles = css`
		ul {
			list-style: none;
			background-color: var(--colour-gray-300);
		}	
    `;
	protected override update(changedProperties: PropertyValues) {
		if (changedProperties.has('ready') && this.ready) {
			this.loadTags().catch(_ => {
				this.error = true;
			});
		}

		super.update(changedProperties);
	}

	private _onInstallScript = (event: CustomEvent) => {
		if (event.type === 'install-script') {
			this.installing = true;
			this.installedOn = event.detail.workspace.name;

			this.installed = false;

			onAddTagPressed(event.detail.workspace.path)
				.then(this.loadTags)
				.then(() => {
					this.installedOn = event.detail.workspace.name;
					this.installed = true;
					this.installing = false;
				});
		}
	};

	override render() {
		if (!this.ready) {
			return null;
		}

		if (this.error) {
			return html`<p>Error</p>`;
		}

		if (this.loading) {
			return html`<p>Loading...</p>`;
		}


		if (this.installing) {
			return html`<p>⏳Installing script in ${this.installedOn}</p>`;
		}

		return html`
            <ul>
                ${this.accounts.map(account => html`
                    <gtm-element .element=${account} @install-script=${this._onInstallScript}></gtm-element>`)}
            </ul>
			${this.installed ? html`<p>✅ Script installed on ${this.installedOn}</p>` : ''}
			${this.installed ? html`<gtm-script-checker></gtm-script-checker>` : ''}
		`;
	}
}
